import * as React from "react"
import '../style/style.css';
import Layout from '../components/Layout';
import Carousel from '../components/Carousel';
import Nosotros from '../components/Nosotros';
import ProductsSlider from '../components/ProductsSlider';
import ContactForm from '../components/ContactForm';


const IndexPage = () => {
    return (
    <main>
    <Layout>
      <Carousel />
      <Nosotros className={'nosotros-section'}/>
      <ProductsSlider />
      <ContactForm />
    </Layout>
  </main>
  );
}

export default IndexPage

export { Head } from "../components/Head";