import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { graphql,useStaticQuery } from 'gatsby';
import { GatsbyImage,getImage, withArtDirection } from 'gatsby-plugin-image';
import styled from 'styled-components';
import MainButton from '../components/MainButton.js';
import Title from '../components/Title.js';
import Text from '../components/Text.js';
import { theme } from '../style/theme';
import { device } from './Devices.js';

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Pagination, Navigation, Autoplay } from "swiper";
import 'swiper/css';
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";



const Section = styled.section`
  height: 100vh;
  margin-top: -4%;

  .swiperProducts, .productsImageBanner, .imgProductsImageBanner{
    height: 100vh;
  }

  .imgProductsImageBanner{
    object-position: center;
  }

  @media ${device.laptop}  {
    margin-top: -6%;
  }

  @media ${device.tablet}{
    margin-top: -8%;
  }

  @media ${device.mobileL}{
    margin-top: -10%;

    .home-product-brush{
      left: 1px !important;
      bottom: 0px;
      height: 25px;
    }

    .imgProductsImageBanner{
      object-position: center 72% !important;
    }
  }

  @media ${device.mobileM}{
    .imgProductsImageBanner{
      object-position: center 100% !important;
    }

    .home-product-brush{
      left: 1px !important;
      bottom: 0px;
      height: 25px;
    }
  }

  @media ${device.mobileS}{
    .imgProductsImageBanner{
      object-position: center 110% !important;
    }
  }
`;

const CarouselCaption = styled.div`
    display: flex;
    flex-direction: column;
    left: 0 !important;
    top: 0;
    margin: ${props => props.lessMargin ? '5% 0%' : '0% 0%'};
    padding-left:  ${theme.paddings.left};
    text-align: left !important;
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    height: 100vh;
    justify-content: ${props => props.lessMargin ? 'start' : 'center'};
    width: ${props => props.lessMargin ? '60%' : '50%'};;

    .ProdCarText{
      white-space: inherit;
      width: 90%;
    }

    @media screen and (max-height: 660px){
      margin: ${props => props.lessMargin ? '2% 0' : '0 0'};
    }

    @media ${device.laptopM}{
      padding-left: ${theme.paddings.leftLaptopM};
    }

    @media ${device.laptop}{
      padding-left: ${theme.paddings.leftLaptop};
    }

    @media ${device.tablet}{
      align-items: center;
      padding: ${theme.paddings.mainTablet};
      margin: 0;
      justify-content: start;
      margin-bottom: 10%;
      padding-top: 8%;
      width: 100%;

      .ProdCarTitle{
        text-align: center;
      }

      .ProdCarText {
        font-size: 0.9rem;
        text-align: center;
        width: 87% !important;
      }
    }

    @media ${device.mobileL}{
      padding-top: 12%;
    }
    `; 

const Subtitle = styled.h2`
    font-family: ${theme.fonts.type.subtitle};
    font-size: ${theme.fonts.size.l.subtitle};
    color: ${theme.colors.text.subtitle};
    margin-bottom: ${props => props.lessMargin ? '0' : '4%'};

    @media ${device.tablet}{
      text-align: center;
      font-size: 1.10rem;
    }
`;

const KnowText = styled(Text)`
    position: relative;
    top:0;
    margin-top: 5%;

    @media ${device.tablet}{
      bottom: 15%;
      position: absolute;
      text-align: center;
      top: initial;
      height: fit-content;
      margin: 0;
    }

    @media ${device.mobileM}{
      text-align: center;
    }
`;

const ButtonStyled = styled(MainButton)`
    display: ${props => props.text === '' ? 'none' : 'flex'};
    position: relative;
    margin-top: 2%;
    top: 0;

    @media ${device.tablet}{
      position: absolute !important;
      top: initial !important;
      bottom: 9%;
    }

    @media ${device.mobileL}{
      z-index: 9;
      top: 93%;

      > span {
        font-size: ${theme.fonts.size.m.buton} !important;
      }
    }

    @media ${device.mobileS}{
      
    }
`;

const ProductsSlider = () => {
    const data = useStaticQuery(graphql`
  query {
    site {
        siteMetadata {
          productosCarousel {
            button
            description
            id
            knowText
            title
            subtitle
            color
            href
          }
        }
      }

      allFile(
        sort: {fields: base, order: ASC}
        filter: {relativeDirectory: {eq: "Home/ProductosBanner"}}
      ) {
        nodes {
          childImageSharp {
            id
            gatsbyImageData(
              quality: 100
              placeholder: BLURRED
            )
          }
        }
      }

      MobileBanners: allFile(
        sort: {fields: base, order: ASC}
        filter: {relativeDirectory: {eq: "Home/ProductosBannerMobile"}}
      ) {
        nodes {
          childImageSharp {
            id
            gatsbyImageData(
              quality: 100
              placeholder: BLURRED
            )
          }
        }
      }

      brushLine: file(relativePath: {eq: "Home/BrushLineProduct.png"}) {
        childImageSharp {
          gatsbyImageData
          id
        }
      }

      brushLineMobile: file(relativePath: {eq: "Home/BrushLineProductMobile.png"}) {
        childImageSharp {
          gatsbyImageData
          id
        }
      }
    }
  `);

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
        return '<span class="' + className + '">0' + (index + 1) + "</span>";
    }
  };

    return (
      <Section id="productos">
          <Swiper 
              className='swiperProducts'
              effect={"fade"}
              modules={[EffectFade, Navigation, Pagination, Autoplay]}
              navigation={true}
              pagination={pagination}
              loop={true}
              autoplay={{
                  delay: 20000,
                  disableOnInteraction: false,
                }} 
          >
              {
                  data.site.siteMetadata.productosCarousel.map((item, index) => (

                  <SwiperSlide key={index} >
                      <GatsbyImage
                          image={withArtDirection(getImage(data.allFile.nodes[index].childImageSharp.gatsbyImageData),[
                            {
                              media: "(max-width: 420px)",
                              image: getImage(data.MobileBanners.nodes[index].childImageSharp.gatsbyImageData)
                            },
                        ])}
                          alt={item.title}
                          className='productsImageBanner'
                          imgClassName='imgProductsImageBanner'
                      />

                      <CarouselCaption key={item.id} lessMargin={index === 0 ? true : false}>
                          <Title className={'ProdCarTitle'} color={item.id === 1 ? '#182440' : item.color} title={item.title} />
                          <Subtitle lessMargin={index === 0 ? true : false}>{item.subtitle}</Subtitle>
                          <Text className={'ProdCarText'} text={item.description} />
                          <KnowText className={'home-product-text'} text={item.knowText} />
                          <ButtonStyled className={`productBannerBtn_` & item.id} 
                                      text={item.button} 
                                      color={item.color} 
                                      href={item.href}
                                      />
                      </CarouselCaption>
                      <GatsbyImage image={withArtDirection(getImage(data.brushLine.childImageSharp.gatsbyImageData),[
                                  {
                                    media: "(max-width: 420px)",
                                    image: getImage(data.brushLineMobile.childImageSharp.gatsbyImageData)
                                  },
                              ])}
                                    alt='Line' 
                                    className='home-product-brush'
                                    imgClassName='home-img-product-brush'
                                    style={{ display: 'flex', zIndex: '9999', position: 'absolute', bottom: '-1px', left: '0px' }}
                      />
                  </SwiperSlide>
              )) 
              }
              

          </Swiper>
        </Section>
    );
  }

  
  export default ProductsSlider;