import * as React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import { theme } from '../style/theme.js';
import MainButton from '../components/MainButton.js';
import { device } from './Devices.js';

const Section = styled.section`
    height: 102vh;
    position: relative;
    width: 100%;
    z-index: 10;

    .nosotros-background{
        position: absolute;
        height: 100vh;
        z-index: -1;
    }

    .img-nosotros-background{
        object-position: bottom;
    }

    @media ${device.mobileM}{
        .img-nosotros-background{
            /* object-fit: fill !important; */
        }
    }

    @media ${device.mobileS}{
        height: 125vh;

        .nosotros-background{
            height: 125vh;
        }
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 55%;
    height: 94vh;
    justify-content: center;
    width: 25%;

    > h1 {
        font-family: ${theme.fonts.type.titles};
        font-size: ${theme.fonts.size.l.title};
        color: ${theme.colors.text.main};
        margin-bottom: 10%;
        width: 170%;
    }

    @media ${device.laptop}{
        > h1{
            font-size: 2.6rem;
        }
    }

    @media ${device.tablet} {
        padding: 2% 3%;
        margin-left: 0;
        justify-content: start;
        width: 100%;

        > h1 {
            font-size: ${theme.fonts.size.m.title};
            text-align: center;
            margin-bottom: 4%;
            width: 100%;
        }


    }

    @media ${device.mobileL}{
        padding: 0% 3% 67% 3%;
    }
`;

const WrapperItem = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 4%;
    width: 100%; 

    h2 {
        font-family: ${theme.fonts.type.h2};
        font-size: ${theme.fonts.size.l.h2};
        color: ${theme.colors.text.main};
    }

    span{
        font-family: ${theme.fonts.type.text};
        font-size: ${theme.fonts.size.l.text};
        color: ${theme.colors.text.main};
        width: 100%;
    }

    @media ${device.laptopM}{
        width: 110%;
    }

    @media ${device.laptop}{
        width: 132%;
    }

    @media ${device.tablet}{
        align-items: center;
        text-align: center;
        margin-bottom: 6%;
        width: 100%;

        h2{
            font-size: ${theme.fonts.size.m.h2};
            text-align: center;
        }

        span{
            font-size: ${theme.fonts.size.m.text};
            width: 90%;
        }
    }

    @media ${device.mobileM}{
        h2{
            font-size: ${theme.fonts.size.s.subtitle};
            margin-bottom: 0.3%;
        }

        span{
            font-size: ${theme.fonts.size.s.text};
        }
    }
`;

const Button = styled(MainButton)`
    position: relative !important;
    margin-top: 2%;
    text-align: center;
    top: 0;
    width: 112px !important;
    
    > span{
        font-size: 0.8rem !important;
        width: 100% !important;
    }
`;

const NosotrosSection = ( { className } ) => {
    const data = useStaticQuery(graphql`
        query{
            file(relativePath: {eq: "Home/nosotros-background.png"}) {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                    )
                }
              }

            mobile:   file(relativePath: {eq: "Home/nosotros-background-mobile.jpg"}) {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                  )
                }
            }

            site {
                siteMetadata {
                    nosotrosInfo {
                        button
                        text
                        title
                        href
                    }
                }
            }
        }
    `);

    const background = withArtDirection(getImage(data.file.childImageSharp.gatsbyImageData),[
        {
          media: "(max-width: 420px)",
          image: getImage(data.mobile.childImageSharp.gatsbyImageData)
        },
    ])

    const infoSections = data.site.siteMetadata.nosotrosInfo

    return(
        <Section id="nosotros">
            <GatsbyImage 
                alt='nosotros-background'
                image={background}
                tag='div'
                className='nosotros-background'
                imgClassName='img-nosotros-background'
                />

            <Container >
                <h1>¿Por qué Livra?</h1>
                {
                    infoSections.map((section, index) => (
                        <WrapperItem key={index}>
                            <h2>{section.title}</h2>
                            <span>{section.text}</span>
                            <Button text={section.button} color={theme.colors.background.main} href={section.href}></Button>
                        </WrapperItem>
                    ))
                }
            </Container>            
        </Section>
    );
}

export default NosotrosSection;