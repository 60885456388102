import React from "react";
import styled from 'styled-components';
import { theme } from '../style/theme.js'
import { device } from "./Devices.js";
import Title from '../components/Title.js';
import Text from '../components/Text.js';

const Container = styled.section`
    display: flex;
    flex-direction: column;
    padding: ${theme.paddings.main};
    margin: 6% 0;
    width: 100%;

    @media ${device.tablet}{
        padding: ${theme.paddings.mainTablet};

        .TitleContact, .TextContact {
            text-align: center;
            width: 100%;
        }

        .TextContact {
            margin: 2% 0;
        }
    }
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 4%;

    @media ${device.tablet}{
        flex-direction: column;
    }
`;

const FormWrapper = styled.form`
    border: 1px solid ${theme.colors.background.main};
    display: flex;
    flex-direction: column;
    padding: 0.5%;
    width: 68%;

    @media ${device.tablet}{
        width: 100%;
    }
`;

const InfoWrapper = styled.div`
    background-color: ${theme.colors.background.text};
    display: flex;
    flex-direction: column;
    width: 31%;
    margin-left: 2%;
    padding: 2.5%;
    height: fit-content;
    
    > h2{
        padding:0;
        margin-bottom: 1%;
    }

    > span{
        margin-bottom: 2%;
    }

    @media ${device.tablet}{
        margin-left: 0;
        margin-top: 5%;
        width: 100%;

        .InfoEmp {

        }
    }
`;

const FormTitle = styled.h2`
    align-items: center;
    background-color: ${theme.colors.background.text};
    display: flex;
    font-family: ${theme.fonts.type.h2};
    font-size: ${theme.fonts.size.l.h2};
    color: ${theme.colors.text.main};
    height: 50px;
    padding-left: 2%;
    margin-bottom: 5%;
    width: 100%;

    @media ${device.tablet} {
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-size: 0.83rem;
        font-weight: bold;
        margin-bottom: 3%;
    }
`;

const WrapperRubro = styled.div`
    display: flex; 
    flex-direction: row;
    width: 100%;

    li{
        background-color: ${theme.colors.background.main};
        cursor: pointer;
        list-style: none;
        height: 40px;
        margin: 0 2%;
        position: relative;
        width: 50%;

        label {
            align-items: center;
            color: white;
            cursor: pointer;
            display: flex;
            font-family: ${theme.fonts.type.buttons};
            font-size: 0.9rem;
            height: 40px;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
        }

        input[type="radio"]{
            opacity: 0.01;
            z-index: 100;
            position: absolute;
            width: 1%;
        }

        input[type="radio"]:checked+label{
            background: ${theme.colors.background.menuItem};
        }
    }

    @media ${device.tablet}{
        justify-content: center;
        flex-wrap: wrap;

        li{
            margin: 1% 1%;
            width: 46%;
        }

        label {
            font-size: ${theme.fonts.size.m.buton};
        }
    }
`;

const ColumnsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    @media ${device.tablet}{
        flex-direction: column;
    }
`;

const WrapperColInput = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2% 2%;
    width: 50%;

    input {
        appearance: none;
        border: none;
        border-bottom: 1px solid ${theme.colors.background.main};
        font-family: ${theme.fonts.type.placeholder};
        margin: 2% 0;
        width: 100%;
    }

    input:focus {
        outline: none;
    }

    #Nombre, #Telefono, #Provincia, #CPostal {
        margin-right: 4%;
    }

    @media ${device.tablet}{
        padding: 0 2%;
        width: 100%;
    }
`;

const WrapperInput = styled.div`
    display: flex;
    flex-direction: row;
    padding: 2% 2%;
    width: 100%;

    textarea{
        border: 1px solid ${theme.colors.background.main};
        font-family: ${theme.fonts.type.placeholder};
        resize: none;
        width: 100%;

        :focus{
            outline: none;
        }
    }

    input[type="file"]{
        border: none;
        display: none;
    }

    #lblArchivo{
        align-items: center;
        background-color: ${theme.colors.background.main};
        color: white;
        cursor: pointer;
        display: flex;
        font-family: ${theme.fonts.type.buttons};
        font-size: 0.9rem;
        height: 40px;
        justify-content: center;
        width: 30%;
    }

    button {
        background-color: ${theme.colors.background.menuItem};
        color: white;
        font-family: ${theme.fonts.type.buttons};
        border: none;
        width: 15%;
    }

    div {
        align-items: center;
        display: flex;
        font-family: ${theme.fonts.type.placeholder};
        font-size: 0.6rem;
        padding-right: 26%;
        margin-left: 2%;
        line-height: 1;
        white-space: pre-line;
        width: 53%;
    }

    @media ${device.tablet}{
        button, #lblArchivo{
            font-size: ${theme.fonts.size.m.buton};
            height: 40px;
        }

        #lblArchivo{
            width: 42%;
        }
        
        button {
            width: 30%;
        }

        #fileInstruction{
            visibility: hidden;
            height: 40px;
            width: 10%;
        }
    }

    @media ${device.mobileL}{
        input[type="file"]{
            width: 1%;
        }
    }

`;



const ContactForm = () => {
    return (
        <Container id="contacto">
            <Title className='TitleContact' color={theme.colors.text.main} title="Contactate con nosotros"></Title>
            <Text className='TextContact' 
                    text='Estamos encantados de contactarnos con vos para responder a cualquier
                         consulta que tengas. Completá el siguiente formulario y te daremos una 
                         respuesta a la brevedad.' 
            />
            <FormContainer >
                <FormWrapper method="POST" action="https://getform.io/f/7cef7d28-0b7c-44a1-a607-dab43ffbfaff">
                    <FormTitle>ATENCIÓN PERSONALIZADA</FormTitle>
                    <WrapperRubro>
                        <li>
                            <input id="rubroCf" type="radio" name="rubro" value="Consumidor Final" defaultChecked={true}></input>
                            <label htmlFor="rubroCf">CONSUMIDOR FINAL</label>
                        </li>
                        <li>
                            <input id="rubroD" type="radio" name="rubro" value="Distribuidor"></input>
                            <label htmlFor="rubroD">DISTRIBUIDOR</label>
                        </li>
                        <li>
                            <input id="rubroPv" type="radio" name="rubro" value="Punto de Venta"></input>
                            <label htmlFor="rubroPv">PUNTO DE VENTA</label>
                        </li>
                    </WrapperRubro>
                    <ColumnsContainer>
                        <WrapperColInput>
                            <input type="text" placeholder="Nombre y apellido" name="Nombre" id="Nombre"></input>
                            <input type="tel" placeholder="Teléfono" id="Telefono" name="Telefono"></input>
                            <input type="text" placeholder="Provincia" id="Provincia" name="Provincia"></input>
                            <input type="text" placeholder="Código postal" id="CPostal" name="CPostal"></input>
                        </WrapperColInput>
                        <WrapperColInput>
                            <input type="email" placeholder="E-mail" name="Email"></input>
                            <input type="text" placeholder="País" name="Pais"></input>
                            <input type="text" placeholder="Localidad" name="Localidad"></input>
                            <input type="text" placeholder="Calle y altura" name="Domicilio"></input>
                        </WrapperColInput>
                    </ColumnsContainer>
                    <WrapperInput>
                        <textarea  type="text" placeholder="Mensaje" name="Mensaje"></textarea>
                    </WrapperInput>
                    <WrapperInput>
                        <label htmlFor="archivo" id="lblArchivo">ADJUNTAR IMAGEN</label>
                        <input type="file" name="archivo" id="archivo"></input>
                        <div id="fileInstruction">
                            {
                            `Máximo 4 MB por archivo.
                            Formatos permitidos: xps, pdf, doc, docx, rtf, txt, xls, xlsx, csv, bmp, png, jpeg o jpg.
                            `
                            }
                        </div>
                        <button type="submit">ENVIAR</button>
                        
                    </WrapperInput>
                </FormWrapper>
                <InfoWrapper>
                    <FormTitle>Produce y comercializa</FormTitle>
                    <Text className="InfoEmp" text="AWANA. Nutrición Animal S.A."/>
                    <FormTitle>Dirección</FormTitle>
                    <Text className="InfoEmp" text="Av. Mosconi 2965, CP: 1419 CABA - Argentina"/>
                    <FormTitle>E-mail</FormTitle>
                    <Text className="InfoEmp" text="administracion@awana.ar"/>
                    <FormTitle>Teléfono</FormTitle>
                    <Text className="InfoEmp" text="+54 9 11 5927 0766"/>
                </InfoWrapper>
            </FormContainer>
        </Container>

    );
}


export default ContactForm;