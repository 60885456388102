import React from 'react';
import styled from 'styled-components';
import igLogo from '../images/ig-logo.png';
import { theme } from '../style/theme';
import { device } from './Devices';

const Content = styled.div`
    display: flex;
    flex-direction: row;
    z-index: 999;
`;

const LinkIg = styled.a`
    text-decoration: none;
    position: absolute;
    bottom: 8%;
    left: ${theme.paddings.left};

    @media ${device.laptopM}{
        left: ${theme.paddings.leftLaptopM};
    }

    @media ${device.laptop}{
        left: ${theme.paddings.leftLaptop};
    }

    @media ${device.laptopS}{
        top: 91%;
    }
`;

const ImgLogo = styled.div`
    background-image: url(${igLogo});
    background-repeat: no-repeat;
    background-size: contain;
    height: 38px;
    width: 24px;
`;

const WrapperText = styled.div`
    display: flex;
    flex-direction: column;

    > span {
        color: white;
        font-family: 'Verdana' !important;
        font-size: 0.4rem;
        margin-left: 6% !important;
    }

    > span:nth-child(1){
        color: white !important;
        font-size: 0.6rem;
        font-weight: bold;
    }
`;

const FollowUs = () => {
    return(
        <LinkIg href="https://www.instagram.com/livra.ar" target='_blank' rel="noreferrer">
            <Content>
                <ImgLogo />
                <WrapperText>
                    <span>SEGUINOS</span>
                    <span>LIVRA.AR</span>
                </WrapperText>
            </Content>
        </LinkIg>

    );
}

export default FollowUs;