import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { graphql,useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import styled from 'styled-components';
import MainButton from '../components/MainButton.js';
import FollowUs from '../components/FollowUs.js';
import Title from '../components/Title.js';
import Text from '../components/Text.js';
import { theme } from '../style/theme';
import { device } from "./Devices";

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Pagination, Navigation, Autoplay } from "swiper";
import 'swiper/css';
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Section = styled.section`
  height: 100vh;

  .swiper {
    height: inherit;
  }

  .home-banner{
    object-position: center bottom;
  }

  @media (max-height: 746px){
    .home-banner{
      object-position: 50% 10% !important;
    }
  }

  @media ${device.laptopS}{
    .home-banner-brush{
      bottom: -5px !important;
    }
  }

  @media ${device.tablet}{
    .home-banner{
      object-position: center;
    }

    .home-banner-brush{
      width: 100%;
      height: 27px;
      bottom: -1% !important;
    }
  }

  @media ${device.mobileL}{
    .home-banner{
      object-position: center 10% !important;
    }
  }

  @media ${device.mobileM}{
    .home-banner{
      object-position: center -10% !important;
    }
  }

  @media ${device.mobileS}{
    .home-banner{
      object-position: center -31% !important;
    }
  }
`;

const CarouselCaption = styled.div`
    display: flex;
    flex-direction: column;
    left: 0 !important;
    top: 0;
    padding-top: 100px;
    height: 100vh;
    padding-left: 20%;
    text-align: left !important;
    position: absolute;
    right: 15%;
    left: 15%;
    color: #fff;
    width: 51%;
    justify-content: center;
    margin-top: -5%;

    .MainCarButton{
      position: initial;
      margin-top: 2%;
    }

    .MainCarButton span{
      text-align: center;
      width: 100%;
    }

    @media (max-width: 1365px){
      .FirstMainCarTitle{
        font-size: 2.8rem;
      }
    }

    @media ${device.laptopM}{
      padding-left: ${theme.paddings.leftLaptopM};

      .MainCarTitle{
        font-size: 2.6rem;
      }

      .MainCarSubtitle{
        font-size: 1.3rem;
      }
    }

    @media ${device.laptop}{
      padding-left: ${theme.paddings.leftLaptop};
    }

    @media ${device.laptopS}{
      justify-content: start !important;
      margin-top: 100px !important;
    }

    @media ${device.tablet}{
      align-items: center;
      margin: 0 !important;
      padding: ${theme.paddings.mainTablet};
      padding-top: 100px;
      width: 100%;

      .MainCarTitle {
        font-size: 2rem;
        text-align: center;
      }

      .MainCarSubtitle{
        font-size: 1.2rem;
        text-align: center;
      }

      .MainCarText {
        font-size: 1.16rem;
        text-align: center;
      }

      .MainCarButton{
        position: absolute !important;
        width: 75%;
        height: 35px;
        top: 81%;
      }

      .MainCarButton span{
        font-size: 1rem !important;
      }
    }

    @media ${device.mobileL}{
      margin: 25% 0;

      .MainCarButton span{
        font-size: ${theme.fonts.size.m.buton} !important;
      }
      .MainCarText {
        font-size: ${theme.fonts.size.m.text};
      }
    }

    @media ${device.mobileM}{
      .MainCarText{
        font-size: ${theme.fonts.size.s.text};
      }
    }

`; 

const Subtitle = styled.h2`
    font-family: ${theme.fonts.type.subtitle};
    font-size: ${theme.fonts.size.l.subtitle};
    color: ${theme.colors.text.subtitle};
`;

const KnowText = styled(Text)`
    position: relative;
    margin-top: 5%;

    @media ${device.tablet}{
      text-align: center;
      width: 100%;
      position: absolute;
      color: white;
      top: 75%;
    }

    @media ${device.mobileL}{
    /*  position: absolute;
      color: white;
      top: 75%;
      */
    }

    @media ${device.mobileM}{
      text-align: center;
    }
`;


const MainCarousel = () => {
    const data = useStaticQuery(graphql`
  query {
        site {
            siteMetadata {
                homeFirstCarousel {
                    id
                    buttonText
                    color
                    img
                    knowText
                    subtitle
                    text
                    title
                    href
              }
          }
        }

        mainbannerImages: allFile(
            filter: {relativeDirectory: {eq: "Home/MainBanner"}}
            sort: {fields: base, order: ASC}
          ) {
            edges {
              node {
                id
                base
                childImageSharp {
                    gatsbyImageData
                }
              }
            }
          }

          mainbannerLaptopImages: allFile(
            filter: {relativeDirectory: {eq: "Home/MainBannerLaptop"}}
            sort: {fields: base, order: ASC}
          ) {
            edges {
              node {
                childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      placeholder: BLURRED
                    )
                }
              }
            }
          }

          mainbannerMobileImages: allFile(
            filter: {relativeDirectory: {eq: "Home/MainBannerMobile"}}
            sort: {fields: base, order: ASC}
          ) {
            edges {
              node {
                childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      placeholder: BLURRED
                    )
                }
              }
            }
          }

          brushLine: file(relativePath: {eq: "Home/BrushLine.png"}) {
            childImageSharp {
              gatsbyImageData
              id
            }
          }

          brushLineMobile: file(relativePath: {eq: "Home/BrushLineMobile.png"}) {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
    }
  `);

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
        return '<span class="' + className + '">0' + (index + 1) + "</span>";
    }
  };

    return (
      <Section id="mainCarouselBanner">
          <Swiper 
              effect={"fade"}
              modules={[EffectFade, Navigation, Pagination, Autoplay]}
              navigation={true}
              pagination={pagination}
              loop={true}
              autoplay={{
                  delay: 10000,
                  disableOnInteraction: false,
                }} 
          >
              {
                  data.site.siteMetadata.homeFirstCarousel.map((item, index) => (
                    
                  <SwiperSlide key={item.id}>
                      <GatsbyImage
                          image={withArtDirection(getImage(data.mainbannerImages.edges[index].node.childImageSharp.gatsbyImageData),[
                                  {
                                    media: "(max-width: 420px)",
                                    image: getImage(data.mainbannerMobileImages.edges[index].node.childImageSharp.gatsbyImageData)
                                  },
                                  {
                                    media: "(max-width: 1290px)",
                                    image: getImage(data.mainbannerLaptopImages.edges[index].node.childImageSharp.gatsbyImageData)
                                  },
                              ])}
                          alt={item.title}
                          className='mainBannerImages'
                          style={{ height: '100%'}}
                          imgStyle={{ height: '100%', minHeight: '99%', objectPosition: 'bottom' }}
                          objectFit='cover'
                          imgClassName='home-banner'
                      />
                      <CarouselCaption firstCaption={item.id === 1 ? true : false}>
                          <Title className={'MainCarTitle '+ (item.id === 1 ? 'FirstMainCarTitle' : '') } color={item.id === 1 ? '#182440' : item.color} title={item.title} />
                          <Subtitle className={'MainCarSubtitle'}>{item.subtitle}</Subtitle>
                          <Text className={'MainCarText'} text={item.text}/>
                          <KnowText className={'MainKarKnowText'} text={item.knowText} />
                          <MainButton className={'MainCarButton'} text={item.buttonText} color={item.color} href={item.href}/>
                      </CarouselCaption>
                      <FollowUs />
                      <GatsbyImage image={withArtDirection(getImage(data.brushLine.childImageSharp.gatsbyImageData),[
                                  {
                                    media: "(max-width: 420px)",
                                    image: getImage(data.brushLineMobile.childImageSharp.gatsbyImageData)
                                  },
                              ])}
                                    alt='Line' 
                                    className='home-banner-brush'
                                    style={{ display: 'flex', zIndex: '9999', position: 'absolute', bottom: '-7px', left: '0' }}
                      />
                  </SwiperSlide>
              )) 
              }
              

          </Swiper>
        </Section>
    );
  }

  
  export default MainCarousel;
